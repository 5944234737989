import React, {useState} from 'react';
import Footer from "../Footer/Footer.js";
import volver from '../../static/img/volver.png';
import email_logo from '../../static/img/correo.png';
import '../../static/css/Login.css';
import Loader from '../Loader/Loader.js';
import {Link} from 'react-router-dom';


export default function ResetPassword(){

	const Url_back = `${process.env.REACT_APP_URL_BACK}`;
	const [email,setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [error,setError] = useState(false);
	const [success,setSuccess] = useState(false);

    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        const pass_reset = {
            "email": email,
        };
        fetch(`${Url_back}/api/v1/users/reset_password/`,{
            method : 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(pass_reset)
        })
        .then(() => {
        	setSuccess(true);
        })
        .catch((error) => {
        	setError(true);
        })
        .finally(() => {
        	setLoading(false);
        })
    };


	return(
		<>
		{loading && <Loader />}
		<div className="volver_login">
			<Link to={`/`}>
				<img src={volver} alt="volver"/>
			</Link>
			<p>Volver</p>
		</div>
		{(!error && !success) ? (
			<>
			<div>
				<form className="reset_form" onSubmit={onSubmit}>
		            <div className="container_reset_divs">
			            <div>
			                <img src={email_logo} alt="email"/>
			            </div>
			            <div>
			              <input
			                name='Correo_reset'
			                type='text'
			                value={email}
			                placeholder="Correo"
			                required
			                id="Correo_reset"
			                onChange={e => setEmail(e.target.value)}
			              />{' '}
			            </div>
			        </div>
					<br/>
				    <div className="boton_reset">
				        <input className="reset" type='submit' value='Obtener contraseña' />
				    </div>  
				</form>
			</div> 
			</>
		 ):(
		 
		 (!error && success) ? (
		 	<>
			<div className="container_form_register">
				<div className="register_header_status">
					<h2>Se le ha enviado un correo con el link de obtención de contraseña.</h2>
				</div>
			</div>
		 	</>
		 ):(
		 	<>
			<div className="container_form_register">
				<div className="register_header_status">
					<h2>Hubo un error al enviar el correo.</h2>
				</div>
			</div>
		 	</>
		 )
		 )
		} 
		<Footer/>
		</>
	);
}