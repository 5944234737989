import React,{useState,useEffect} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import Footer from "../Footer/Footer.js";
import '../../static/css/Registro.css';
import volver from '../../static/img/volver.png';
import editar_logo from '../../static/img/editar1.png'; 
import Loader from '../Loader/Loader.js';

export default function Editar(){

    const Url_back = `${process.env.REACT_APP_URL_BACK}`;
    const navigate = useNavigate();
	let array_users = []
	const [errors,setErrors] = useState(false);
	const [loading,setLoading] = useState(false);
	const [users,setUsers] = useState([]);
	const [typeFooter,setTypeFooter] = useState(false);

	function return_back(){
		navigate('/menu');
	}

	useEffect(() => {
		setLoading(true);
		if (localStorage.getItem('token') !== ''){
			fetch(`${Url_back}/api/v1/custom_users/`,{
				method : 'GET',
				headers : {
					'Content-Type': 'application/json',
                	'Authorization': `Token ${localStorage.getItem('token')}`,
				}
			})
			.then(res => res.json())
			.then(data => {
				data.data.forEach(element => {array_users.push([element.id,element.cliente,element.email,element.user])})
				setUsers(array_users)
			})
			.catch((error) => {
				setErrors(true);
			})
			.finally(() => {
				setLoading(false);
			});
		}
	},[]);

	useEffect(() => {
		if(users.length > 5){
			setTypeFooter(true);
		}
	},[users])

	return (
		<>
		{loading && <Loader/>}
		<div className="volver_medidores">
			<img src={volver} alt="volver" onClick={return_back}/>
			<p>Volver</p>
		</div>
		{!errors ? (
			<>
			<div className="container_button_new_user">
				<Link to={`/menu/registro`}>
					<input id="button_new_user" type="button" value="+ Nuevo"/>
				</Link>
			</div>
			<div className="container_users_list">
				<div className="header_container_users_list_inside">
					<div>
						Nombre Cliente
					</div>
					<div>
						Usuario
					</div>
					<div>
						Correo
					</div>
					<div>
						Editar
					</div>
				</div>
				{users.map((user)=>{
					return(
						
						<div className="container_users_list_inside" key={user[0]}>
							<div>
								<p>{user[1]}</p>
							</div>
							<div>
								<p>{user[3]}</p>
							</div>
							<div>
								<p>{user[2]}</p>
							</div>
							<div>
								<Link to={`${user[0]}`}>
									<img src={editar_logo} alt={user[0]}/>
								</Link>
							</div>
						</div>
					)
				})}
			</div>			
			</>
			):(
			<div className="container_form_register">
            	<div className="register_header_status">
                	<h2>Ocurrio un error al obtener a los usuarios</h2>
            	</div>
       		</div>
		)}
		{!typeFooter ? (
			<Footer/>
		):(
			<Footer position="relative"/>
		)}
		</>
	)
}