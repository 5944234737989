import React from 'react';
import Footer from "../Footer/Footer.js";
import volver from '../../static/img/volver.png';
import {useNavigate} from 'react-router-dom';
import '../../static/css/NotFound.css'

export default function NotLoggin(){

    const navigate = useNavigate();

	function return_back(){
		navigate('/menu');
	}

	return (
		<>
		<div className="volver_medidores">
			<img src={volver} alt="volver" onClick={return_back}/>
			<p>Volver</p>
		</div>
		<div className="container_not_found">
			<h1>Página no encontrada.</h1>
		</div>
		<Footer/>
		</>
	)
}