import React, {useEffect,useState} from 'react';
import {useNavigate} from 'react-router-dom';
import '../../static/css/Menu.css'
import medicion from '../../static/img/grafica.png';
import cliente_logo from '../../static/img/cargas.png'; 
import editar_logo from '../../static/img/editar.png'; 
import Footer from "../Footer/Footer.js";

export default function Menu(){

    const [isAdmin,setIsAdmin] = useState(false);
    const navigate = useNavigate();

    function handleClickMedicion(){
        navigate('/menu/medicion')
    }

    function handleClickRegistro(){
        navigate('/menu/registro')
    }

    function handleClickEditar(){
        navigate('/menu/editar')
    }

    useEffect(() => {
    if (localStorage.getItem('grupo') !== 'clientes'){
       setIsAdmin(true);
    }});

    return (
        <>
        { isAdmin ? (
            <>
            <div>
                <div className="container_buttons_menu">
                    <div className="container_menu" onClick={handleClickRegistro}>
                        <div>
                            <img src={cliente_logo} alt="medicion"/> 
                        </div>
                        <div>
                            <h2>Registrar Cliente</h2>
                        </div>  
                    </div>
                    <div className="container_menu"  onClick={handleClickEditar}>
                        <div>
                            <img src={editar_logo} alt="medicion"/> 
                        </div>
                        <div>
                            <h2>Modificar Cliente</h2>
                        </div>                         
                    </div>
                </div>               
            </div>
            <Footer/>
            </>

            ) : (
            <>
            <div className="main_menu">
                <div className="container_buttons_menu">
                    <div className="container_menu" onClick={handleClickMedicion}>
                        <div>
                            <img src={medicion} alt="medicion"/> 
                        </div>
                        <div>
                            <h2>Mediciones</h2>
                        </div>  
                    </div>
                </div>
            </div>
            <Footer/>
            </>


            )
        }
        </>
        )
}