import React from 'react';
import Footer from "../Footer/Footer.js";

export default function FailedReset(){

	return(
		<>
		<br/>
		<div className="container_form_register">
			<div className="register_header_status">
				<h2>Hubo un error al actualizar el contraseña</h2>
				<h2>Comuniquese con el equipo de TI de CFE Caificados</h2>
			</div>
		</div>
		<Footer/>
		</>
	);
}