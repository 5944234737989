import React from "react";
import "../../static/css/Loader.css";

export default function Loader(props) {
  return (
    <div className="div_loader">
      <div className="inter_div_loader">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div> 
        <div>Loading</div>
      </div>   
    </div>

  );
}