import React,{useState,useEffect} from 'react';
import {useParams} from "react-router-dom";
import Loader from '../Loader/Loader.js';
import '../../static/css/Medidores.css';
import {Link} from 'react-router-dom';
import hora_logo from '../../static/img/hora.png';
import volver from '../../static/img/volver.png';
import suma from '../../static/img/suma.png';
import Footer from "../Footer/Footer.js";
import Mediciones from "../Mediciones/Mediciones.js";
import {AiOutlineSearch} from "react-icons/ai";
//Calendar
import * as rdrLocales from 'react-date-range/dist/locale';
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from 'moment';

export default function Medidores(){

    const Url_back = `${process.env.REACT_APP_URL_BACK}`;
	let {nombre} = useParams();
	const array_nombres = [];
	const [select, setSelect] = useState(false);
	const [horaria,setHoraria] = useState(false);
	const [minutal,setMinutal] = useState(false);
	const [medidores, setMedidores] = useState([]);
	const [sltMedidores, setSltMedidores] = useState([]);
	const [isSearching,setIsSearching] = useState(false);
	const [resultFilter,setResultFilter] = useState([]);
	const [tipo,setTipo] = useState(''); 
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isSuma,setIsSuma] = useState(false);
    const [isDisabled,setIsDisabled] = useState(true);
	const [state, setState] = useState([
		{
		  startDate: new Date(),
		  endDate: new Date(),
		  key: "selection"
		}
	]);
	const [mediciones,setMediciones] = useState([])
	const [flag,setFlag] = useState(false);
	const [isMinutal,setIsMinutal] = useState(JSON.parse(localStorage.getItem('minutal')));


	useEffect(() => {
		if(!isMinutal){
			setMinutal(false);
			setHoraria(true);
			setTipo('horaria');
		}
	},[horaria])

	useEffect(() => {
    if (localStorage.getItem('token') !== '') {
        fetch(`${Url_back}/api/v1/medidores/${nombre}`,{
            method : 'GET',
            headers : {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`,
            },

        })
        .then(res => res.json())
        .then(data => {
        	if(data.status === "OK"){
        		data.data.forEach(element => {array_nombres.push(element.identificador)});
        		setMedidores(array_nombres);
        	}else{
        		setErrors(true);
        	}
        })
        .catch((error) => {
        	setErrors(true);
        })
        .finally(() => {
        	setLoading(false);
        });
    }
    },[]);

	function handlerClickMedidor(e){
		const id = e.target.id;
		setSltMedidores([...sltMedidores,id]);
		setMedidores(medidores.filter(item => item !== id));
		setResultFilter(resultFilter.filter(item => item !==id));
	}

	function handlerClickMedidorDelete(e){
		const id = e.target.id;
		setMedidores([...medidores,id]);
		setSltMedidores(sltMedidores.filter(item => item !== id));
		setResultFilter([...resultFilter,id])
	} 

	function handlerClickTipo(e){
		const tipo = e.target.id;
		setTipo(tipo)
		if(tipo === "minutal"){
			setMinutal(true);
			setHoraria(false);
		}else{
			setMinutal(false);
			setHoraria(true);
		}
		setFlag(false);
	}

	function handlerClickSuma(e){
		if (!isSuma){
			setIsSuma(true);
		}
		else{
			setIsSuma(false);
		}
	}

	function postMedicion(tipo,body_post){

	    if (localStorage.getItem('token') !== '') {
	    	setLoading(true);
	        fetch(`${Url_back}/api/v1/medicion/${tipo}/`,{
	            method : 'POST',
	            headers : {
	                'Content-Type': 'application/json',
	                'Authorization': `Token ${localStorage.getItem('token')}`,
	            },
	            body: JSON.stringify(body_post)
	        })
	        .then(res => res.json())
	        .then(data => {
	        	if(data.status === "OK"){
	        		setMediciones(data.data);
	        		setFlag(true);
	        	}else{
	        		setErrors(true);
	        		setFlag(false);
	        	}
	        })
	        .catch((error) => {
	        	setErrors(true);
	        	setFlag(false);
	        })
	        .finally(() => {
	        	setLoading(false);
	        });
	    }
	}


	function handlerClickPost() {
		setSelect(false)
		if (sltMedidores.length < 1 || (minutal === false && horaria ===false)){
			setSelect(true)
		}else{
			const body = {
				meds: sltMedidores,
				inicio: moment(state[0]['startDate']).format('YYYY-MM-DD'),
				fin: moment(state[0]['endDate']).format('YYYY-MM-DD')
			}
			postMedicion(tipo,body);
		}
	}

	function searching(e){
		var value;
		value = e.target.value.toUpperCase()
		if (value.length > 0){
			setIsSearching(true);
		}else{
			setIsSearching(false);
		}
		setResultFilter(medidores.filter(x => x.toUpperCase().includes(value)))
	}

	useEffect(() => {
		if(sltMedidores.length < 2){
			setIsSuma(false);
			setIsDisabled(true);
		}else{
			setIsDisabled(false);
		}
	},[sltMedidores.length])

	return(
		<>
		{loading && <Loader/>}
		<div className="volver_medidores">
			<Link to={`/menu/medicion`}>
				<img src={volver} alt="volver"/>
			</Link>
			<p>Volver</p>
		</div>
		{(!errors && !flag) ? (
			<>
			<div className="main_container_medidores">
				<div className="container_fechas_medidores">
					<div className="titles_medidores">
						<h3>Seleccionar rango de fechas</h3>
					</div>
					<br/>
					<div className="selector_fechas_medidores">
						<div>
						      <DateRange
						      	locale={rdrLocales.es}
						        onChange={item => setState([item.selection])}
						        moveRangeOnFirstSelection={false}
						        ranges={state}
						        dateDisplayFormat={'yyyy/L/dd'}
						        dateFormat={"yyyy/L/dd"}
						        minDate={new Date(2017, 0, 1)}
						        maxDate={new Date()}
						      />
						</div>
					</div>
				</div>
				<br/>
				<div className="container_medidores_medidores">
					<div className="titles_medidores">
						<h3>Selecionar medidores</h3>
					</div>
					<br/>
					<div className={"search_bar_icon"}>
						<div>
							<AiOutlineSearch style={{ width: "120%", height: "100%"}}/>
						</div>
						<div>
							<input id="input_filter" onChange={searching} type="text" className="input_medidores"  placeholder="Buscar" />
						</div>
					</div>
					<br/>
					<div className="border_selectores_medidores">	
						<div className="selector_medidores_medidores" id="main_selector">
						{
							isSearching ? (
								
						        resultFilter.map((medidor) => {
						          return (
									<div key={medidor} onClick={handlerClickMedidor}>
									    <div className="identificador_medidores" id={medidor}> 
									        {medidor}
									    </div>
								    </div>
						          );
						        })
							) : (

						        medidores.map((medidor) => {
						          return (
									<div key={medidor} onClick={handlerClickMedidor}>
									    <div className="identificador_medidores" id={medidor}> 
									        {medidor}
									    </div>
								    </div>
						          );
						        })
							)
						}
						</div>
					</div>
					<br/>
					<div className="container_suma_medidores">
						<div>
							<button className={isSuma ? 'selected' : 'no-selected'} disabled={isDisabled} onClick={handlerClickSuma}>
								<img id="suma" src={suma} alt="logo_suma"/>
							</button>
						</div>
						<div>
							<h3>{isSuma ? "Desactivar " : "Activar "}suma de medidores</h3>
						</div>
					</div>
					<br/>
					<div className="border_selectores_medidores">
						<div className="selecionado_medidores_medidores">
							{sltMedidores.map((select) => {
									return(
										<div key={select} onClick={handlerClickMedidorDelete}>
										    <div className="identificador_medidores_seleccionado" id={select}> 
										        {select}
										    </div>
									    </div>										
									)		
								})}
						</div>
					</div>
				</div>
				<br/>
				{ isMinutal && (
				<>
				<div className="conatiner_tipo_medidores">
					<div className="titles_medidores">
						<h3>Selecionar el tipo de medición</h3>
					</div>
					<br/>
					<div className="selector_tipo_medidores">
						<div className="inter_selector_tipo">
							<div>
								<div>
									<h4>Minutal</h4>
									<button className={minutal ? 'selected' : 'no-selected'} onClick={handlerClickTipo}>
										<img id="minutal" src={hora_logo} alt="hora_logo_minutal"/>
									</button>
								</div>
							</div>
						</div>
						<div className="inter_selector_tipo">
							<div>
								<div>
									<h4>Horaria</h4>
									<button className={horaria ? 'selected' : 'no-selected'} onClick={handlerClickTipo}>
										<img id="horaria" src={hora_logo} alt="hora_logo_horaria"/>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br/>
				</>
					)
				}
				<div className="container_button_peticion">
					<input type="submit" className="button_solicitar_medicion" onClick={handlerClickPost} value="Consultar"></input>
				</div>
				{select &&
					( isMinutal ? (
						<div className="error_medidores">
							<h2>Faltan campos por seleccionar, escoja los medidores y el tipo de medición que quiera consultar</h2>
						</div>
						):(
						<div className="error_medidores">
							<h2>Falta escoger medidores</h2>
						</div>
						)
					)
				}
			</div>
			</>
			):(
			<div>
			{errors ? (
				<>
					<div className="error_medidores">
						<h2>Carga inválida</h2>
					</div>
				</>
			):(
				<div>
					<br/>
					{flag && <Mediciones mediciones={mediciones} tipo={tipo} medidores={sltMedidores} select={sltMedidores} fecha_inicio={state[0]['startDate']} fecha_fin={state[0]['endDate']} suma={isSuma}/>}
				</div>
			)
			}
			</div>

		)}
		{	errors ? (
			<Footer/>
			):(
			<Footer position="relative"/>
			)
		}
		</>
		);
}