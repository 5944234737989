import React,{useState,useEffect} from 'react';
import {useParams,useNavigate} from "react-router-dom";
import Loader from '../Loader/Loader.js';
import '../../static/css/Registro.css';
import volver from '../../static/img/volver.png';
import logo_usuario from "../../static/img/usuario.png";
import new_usuario from "../../static/img/nuevousuario.png";
import correo_img from "../../static/img/correo.png";
import Footer from "../Footer/Footer.js";
import {AiOutlineSearch} from "react-icons/ai";

export default function UserEdit(){

    const Url_back = `${process.env.REACT_APP_URL_BACK}`;
    const navigate = useNavigate();
    let {id} = useParams();
    const [error,setError] = useState(false);
    const [loading,setLoading] = useState(true);
    const [user,setUser] = useState('');
    const [email,setEmail] = useState('');
    const [name,setName] = useState('');
    const [cargasSlt,setCargasSlt] = useState([]);
    const [cargas,setCargas] = useState([]);
    const [resultFilter,setResultFilter] = useState([]);
    const [isSearching,setIsSearching] = useState(false);
    const [update,setUpdate] = useState(false);
    const [successfully, setSuccessfully] = useState(false);

    function return_back(){
        navigate('/menu/editar');
    }

    function searching(e){
        var value;
        value = e.target.value.toUpperCase()
        if (value.length > 0){
            setIsSearching(true);
        }else{
            setIsSearching(false);
        }
        setResultFilter(cargas.filter(x => x.toUpperCase().includes(value)))
    }

    function handlerClickCarga(e){
        const id = e.target.id;
        setCargasSlt([...cargasSlt,id]);
        setCargas(cargas.filter(item => item !== id));
        setResultFilter(resultFilter.filter(item => item !==id));
    }

    function handlerClickCargaDelete(e){
        const id = e.target.id;
        setCargas([...cargas,id]);
        setCargasSlt(cargasSlt.filter(item => item !== id));
        setResultFilter([...resultFilter,id])
    } 


    useEffect(() => {
        if (localStorage.getItem('token') !== ''){
            fetch(`${Url_back}/api/v1/custom_user/${id}`,{
                method : 'GET',
                headers : {
                    'Content-Type' : 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                },
            })
            .then(res => res.json())
            .then(data => {
                if(data.status === "OK"){
                    setUser(data.data.usuario);
                    setEmail(data.data.correo);
                    setName(data.data.nombre_cliente);
                    setCargasSlt(data.data.cargas);
                    setCargas(data.data.cargas_filter)
                }
            })
            .catch((error) => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
        }
    },[]);


    const onSubmit = e => {
        e.preventDefault();
        setLoading(true)
        const user_edit = {
        "id" : id,
        "email": email,
        "carga": cargasSlt.toString(),
        "nombre_cliente": name,
        "username": user,
        };
        if(localStorage.getItem('token') !== '' && cargasSlt !== ''){
            fetch(`${Url_back}/api/v1/user_update/`,{
                method : 'PUT',
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(user_edit)
            })
            .then(res => {
                if (res.statusText !== "update"){
                    setUpdate(true);
                    setSuccessfully(true);
                }else{
                    setError(true);
                }
            })
            .catch((error) => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            })
        }
    }

    return(
        <>
        {loading && <Loader/>}
        <div className="volver_medidores">
            <img src={volver} alt="volver" onClick={return_back}/>
            <p>Volver</p>
        </div>
        {(!error && !successfully) ? (
        <>
        <div className="container_form_register">
            <div className="form_register_header">
                <h2>Editar usuario</h2>
            </div>
            <form onSubmit={onSubmit}>
                <div className="container_form_new_user">
                    <div className="sub_header_edit">
                        <h3>Usuario</h3>
                    </div>
                    <div className="form_username">
                        <div>
                            <img src={logo_usuario} alt="logo_usuario"/>
                        </div>
                        <div>
                          <input
                          name="usuario"
                          type='text'
                          placeholder="Usuario"
                          required
                          id="usuario"
                          value = {user}
                          onChange={e=> setUser(e.target.value)}
                          />
                        </div>
                    </div>
                    <hr/>
                    <div className="sub_header_edit">
                        <h3>Cliente</h3>
                    </div>
                    <div className="form_username">
                        <div>
                            <img src={new_usuario} alt="new_usuario"/>
                        </div>
                        <div>
                          <input
                          name="nombre_cliente"
                          type='text'
                          placeholder="Nombre del cliente"
                          required
                          id="nombre_cliente"
                          value = {name}
                          onChange={e=> setName(e.target.value)}
                          />
                        </div>
                    </div>
                    <hr/>
                    <div className="sub_header_edit">
                        <h3>Correo</h3>
                    </div>
                    <div className="form_email">
                        <div>
                            <img src={correo_img} alt="correo"/>
                        </div>
                        <div>
                          <input
                          name="email"
                          type='email'
                          placeholder="Correo"
                          required
                          id="email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                    </div>
                    <hr/>
                </div>
                <div className="form_register_header_selector">
                    <h2>Seleccionar cargas</h2>
                </div>
                <br/>
                <div className={"search_bar_icon"}>
                    <div>
                        <AiOutlineSearch style={{ width: "120%", height: "100%"}}/>
                    </div>
                    <div>
                        <input id="input_filter" onChange={searching} type="text" className="input_medidores"  placeholder="Buscar" />
                    </div>
                </div>
                <br/>
                <div className="border_selectores_cargas">  
                    <div className="selector_registro_cargas"  id="main_selector">
                    {
                        isSearching ? (
                            
                            resultFilter.map((carga) => {
                              return (
                                <div key={carga} onClick={handlerClickCarga}>
                                    <div className="identificador_cargas" id={carga}> 
                                        {carga}
                                    </div>
                                </div>
                              );
                            })
                        ) : (

                            cargas.map((carga) => {
                              return (
                                <div key={carga} onClick={handlerClickCarga}>
                                    <div className="identificador_cargas" id={carga}> 
                                        {carga}
                                    </div>
                                </div>
                              );
                            })
                        )
                    }
                    </div>
                </div>
                <br/>

                <div className="border_selectores_cargas">
                    <div className="selecionado_registro_cargas">
                        {cargasSlt.map((select) => {
                                return(
                                    <div key={select} onClick={handlerClickCargaDelete}>
                                        <div className="identificador_cargas_seleccionado" id={select}> 
                                            {select}
                                        </div>
                                    </div>                                      
                                )       
                            })}
                    </div>
                </div>
                <br/>
                <div className="registrar_boton">
                    <input type='submit' value="Actualizar"></input>
                </div>
            </form>
        </div>      
        <br/>
        <Footer position="relative"/>   
        </>
        ):(      
        (!error && update) ? (
            <>
            <div className="container_form_register">
                <div className="register_header_status">
                    <h2>Usuario actualizado con éxito</h2>
                </div>
            </div>
            <Footer/> 
            </>
        ):(
        <>
        <div className="container_form_register">
            <div className="register_header_status">
                <h2>Ocurrio un error</h2>
            </div>
        </div>
        <Footer/> 
         </>   
        )
        )}

        </>
    )

}