import React from 'react';
import Footer from "../Footer/Footer.js";
import {Link} from 'react-router-dom';
import '../../static/css/Login.css';

export default function SuccessfullyReset(){
	return(
		<>
		<br/>
		<div className="container_form_register">
			<div className="register_header_status">
				<h2>Se le envío un correo con la nueva contraseña</h2>
				<Link to={`/`}>
                    <p>Ir al login</p>
               	</Link>
			</div>
		</div>
		<Footer/>
		</>
	);
}