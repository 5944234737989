import React from 'react';
import Footer from "../Footer/Footer.js";
import '../../static/css/NotLoggin.css'

export default function NotLoggin(){
	return (
		<>
		<div className="container_not_loggin">
			<h1>Para acceder al servicio es necesario iniciar sesión en:</h1>
			<a href="https://clientes.cfecalificados.mx/">Clientes Calificados</a>
		</div>
		<Footer/>
		</>
	)
}