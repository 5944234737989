import React,{useState,useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import '../../static/css/Mediciones.css';
import volver2 from '../../static/img/volver2.png';
import volver from '../../static/img/volver.png';
import enviar from '../../static/img/enviar.png';
import descargar from '../../static/img/descargar.png';
//import hora from './static/img/hora.png';
import noInfo from '../../static/img/nohayregistros.png';
import Loader from '../Loader/Loader.js';
//Calendar
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from 'moment';

export default function Mediciones({mediciones,tipo,select,fecha_inicio,fecha_fin,suma}){

	const Url_back = `${process.env.REACT_APP_URL_BACK}`;
	const navigate = useNavigate();
	const data = {mediciones}['mediciones']
	const med = {}
	const [meds,setMeds] = useState({});
	const [loading, setLoading] = useState(false);
	const [isData,setIsData] = useState(true);
	const [isSuma,setIsSuma] = useState(false);
	const [errorEmail,setErrorEmail] = useState(false);
	const [missing,setMissing] = useState(false);
	const [datesMissing,setDatesMissing] = useState([]);
	let table_info = []
	let temp_table = []

	useEffect(() => {
		let dates = [];
		var inicio = moment(fecha_inicio).startOf('day');
		var fin = moment(fecha_fin).startOf('day');
		dates.push(inicio.format('YYYY-MM-DD'));

		while(inicio.add(1,'days').diff(fin) <= 0){
			dates.push(inicio.clone().format('YYYY-MM-DD'));
		}
		window.scrollTo(0,0);
		if (suma){
			if (select.length >= 2){
				setIsSuma(true)
			}
		}
		if (data.length < 1){
			setIsData(false)
            setMissing(true)
            setDatesMissing(dates)

		}else{
			if (tipo === 'horaria'){
				for(let index in data){
					const date = data[index].fecha
					if(!med.hasOwnProperty(date)){
						med[date] = {}
						const hour = data[index].hora
						med[date][hour] = {}
						med[date][hour][data[index].identificador] = {}
						med[date][hour][data[index].identificador] = [data[index].kwh.toFixed(4),data[index].tipo]
					}else{
						const hour = data[index].hora
						if(med[date].hasOwnProperty(hour)){
							med[date][hour][data[index].identificador] = {}
							med[date][hour][data[index].identificador] = [data[index].kwh.toFixed(4),data[index].tipo]
						}else{
							med[date][hour] = {}
							med[date][hour][data[index].identificador] = {}
							med[date][hour][data[index].identificador] = [data[index].kwh.toFixed(4),data[index].tipo]
						}
					}
				}
			}else{
				for (let index in data){
					const ts_s = data[index].timestamp
					if(!med.hasOwnProperty(ts_s)){
						med[ts_s] = {}
						med[ts_s][data[index].identificador] = {}
						med[ts_s][data[index].identificador] = [parseFloat(data[index].kwhe).toFixed(4), data[index].tipo] /*Esto es debido a que es un chartfield en la base*/
					}else{
						med[ts_s][data[index].identificador] = {}
						med[ts_s][data[index].identificador] = [parseFloat(data[index].kwhe).toFixed(4), data[index].tipo] /*Esto es debido a que es un chartfield en la base*/
					}

				}
			}
			let fechas = Object.getOwnPropertyNames(med);
			let difference = fechas
                 .filter(x => !dates.includes(x))
                 .concat(dates.filter(x => !fechas.includes(x)));
            if(difference.length > 0){
            	setMissing(true)
            	setDatesMissing(difference)
            }
			setMeds(med)
		}
	},[])


	function create_table() {
		let key_id = 1
		if (!isSuma){
			if(tipo === 'horaria'){
				const fechas = Object.keys(meds)
				for (let i in fechas){
					const horas = Object.keys(meds[fechas[i]])
					for (let hora in horas){
						const mediciones_id = Object.keys(meds[fechas[i]][horas[hora]])
						temp_table.push(<td>{fechas[i]}</td>)
						temp_table.push(<td>{horas[hora]}</td>)
						for (let m in mediciones_id){
							if(meds[fechas[i]][horas[hora]][mediciones_id[m]][1] === "E"){
								temp_table.push(<td>{meds[fechas[i]][horas[hora]][mediciones_id[m]][0]}*</td>)

							}else{
								temp_table.push(<td>{meds[fechas[i]][horas[hora]][mediciones_id[m]][0]}</td>)
							}
													}
						table_info.push(<tr key={key_id}>{temp_table}</tr>)
						temp_table = []
						key_id = key_id + 1
					} 
				}
				return table_info
			}else{
				const timestamp = Object.keys(meds)
				for (let j in timestamp){
					const mediciones_id = Object.keys(meds[timestamp[j]])
					temp_table.push(<td>{timestamp[j]}</td>)
					for (let m in mediciones_id){
						if(meds[timestamp[j]][mediciones_id[m]][1] === "E"){
							temp_table.push(<td>{meds[timestamp[j]][mediciones_id[m]][0]}*</td>)
						}else{
							temp_table.push(<td>{meds[timestamp[j]][mediciones_id[m]][0]}</td>)
						}
						
					}
					table_info.push(<tr key={key_id}>{temp_table}</tr>)
					temp_table = []
					key_id = key_id + 1
				}
				return table_info
			}
		}else{
			var flag = false
			if(tipo === 'horaria'){
				const fechas = Object.keys(meds)
				for (let i in fechas){
				const horas = Object.keys(meds[fechas[i]])
					for (let hora in horas){
						const mediciones_id = Object.keys(meds[fechas[i]][horas[hora]])
						temp_table.push(<td>{fechas[i]}</td>)
						temp_table.push(<td>{horas[hora]}</td>)
						let sum = 0
						for (let m in mediciones_id){
							if (meds[fechas[i]][horas[hora]][mediciones_id[m]][1] === "E"){
								flag = true
							}
							sum = sum + parseFloat(meds[fechas[i]][horas[hora]][mediciones_id[m]][0])
						}
						if (flag){
							temp_table.push(<td>{sum.toFixed(4)}*</td>)	
						}else{
							temp_table.push(<td>{sum.toFixed(4)}</td>)
						}
						flag = false
						table_info.push(<tr key={key_id}>{temp_table}</tr>)
						temp_table = []
						sum = 0
						key_id = key_id + 1
					} 
				}
				return table_info			
			}else{
				const timestamp = Object.keys(meds)
				for (let j in timestamp){
					const mediciones_id = Object.keys(meds[timestamp[j]])
					temp_table.push(<td>{timestamp[j]}</td>)
					let sum = 0
					for (let m in mediciones_id){
						if(meds[timestamp[j]][mediciones_id[m]][1] === "E"){
							flag=true
						}
						sum = sum + parseFloat(meds[timestamp[j]][mediciones_id[m]][0])
					}
					if (flag){
						temp_table.push(<td>{sum.toFixed(4)}*</td>)
					}else{
						temp_table.push(<td>{sum.toFixed(4)}</td>)
					}
					
					table_info.push(<tr key={key_id}>{temp_table}</tr>)
					temp_table = []
					sum = 0
					key_id = key_id + 1
				}
				return table_info

			}

		}
	}


	function return_back(){
		setLoading(true);
		window.location.reload(true);
		window.scrollTo(0,0);
	}


	function htmlToCSV(html, filename, tipo) {
		var data = [];
		data.push("Fecha(s):,")
		data.push(moment(fecha_inicio).format('YYYY-MM-DD') + ' - ' + moment(fecha_fin).format('YYYY-MM-DD') +",")
		data.push("Las mediciones están en KWH,");
		data.push("Las mediciones con * son estimadas,")
		if(missing){
			data.push("Fechas faltantes,")
			var temp_date = ""
			for (let indexDate=0; indexDate < datesMissing.length; indexDate++){
				temp_date = temp_date + datesMissing[indexDate]+','
			}
			data.push(temp_date)
		}
		if(isSuma){
			data.push("Medidores seleccionados,")
			var temp = ""
			for (let index=0; index < select.length; index++){
				temp = temp + select[index]+','
			}
			data.push(temp)
		}
		var rows = document.querySelectorAll("table tr");
		for (var i = 0; i < rows.length; i++) {
			var row = [], cols = rows[i].querySelectorAll("td, th");		
			for (var j = 0; j < cols.length; j++) {
			        row.push(cols[j].innerText);
	        }	        
			data.push(row.join(",")); 		
		}
		if (tipo === "descarga"){
			downloadCSVFile(data.join("\n"), filename);
		}else{
			sendCSVFIle(data.join("\n"), filename);
		}
	}	

	function downloadCSVFile(csv, filename) {
		var csv_file, download_link;
		csv_file = new Blob([csv], {type: "text/csv"});
		download_link = document.createElement("a");
		download_link.download = filename;
		download_link.href = window.URL.createObjectURL(csv_file);
		download_link.style.display = "none";
		document.body.appendChild(download_link);
		download_link.click();
	}

	function sendCSVFIle(csv,filename){
		var csv_file;
		csv_file = csv;
		const body_email = {
			file : csv_file,
			fileName : filename
		}
		if(localStorage.getItem('token') !== ''){
			setLoading(true);
			fetch(`${Url_back}/api/v1/send_email/`,{
				method: 'POST',
				headers : {
	                'Content-Type': 'application/json',
	                'Authorization': `Token ${localStorage.getItem('token')}`,					
				},
				body: JSON.stringify(body_email)
			})
			.then(res=>res.json())
			.then(data => {
				if (data.status === "OK"){
					setErrorEmail(false);
				}else{
					setErrorEmail(true);
				}
			})
			.catch((error) => {
				setErrorEmail(true);
			})
			.finally(() => {
				setLoading(false);
			});
		}

	}

	function descargar_csv(){
		let name = ""
		select.forEach(med => name = name + med) 
		name = name + "_" + moment(fecha_inicio).format('YYYY-MM-DD')+ "_" + moment(fecha_fin).format('YYYY-MM-DD') +".csv"
		var html = document.querySelector("table").outerHTML;
		htmlToCSV(html, name, "descarga");
	}

	function enviar_csv(){
		let name = ""
		select.forEach(med => name = name + med) 
		name = name + "_" + moment(fecha_inicio).format('YYYY-MM-DD')+ "_" + moment(fecha_fin).format('YYYY-MM-DD') +".csv"
		var html = document.querySelector("table").outerHTML;
		htmlToCSV(html, name, "correo");	
	}

	return(
		<>
		{loading && <Loader/>}
		<div>
			<div className="container_mediciones_header">
				<h2>Resultado de la consulta</h2>
			</div>
			<br/>
			<div className="container_disclaimer">
				<h3>Descargo de responsabilidad por presentación de mediciones horarias</h3>
				<p>Con el fin de brindar las herramientas necesarias que coadyuven al Cliente al conocimiento de su 
				carga, y le permitan mejorar la elaboración de pronósticos, el Suministrador da acceso a la siguiente 
				información respecto</p> 
				<p>a la medición horaria de las cargas del Cliente. Para acceder a éstas, será 
				importante aceptar lo siguiente:</p>
				<p>*El Cliente acepta que la medición horaria que sea presentada en la aplicación no será definitiva, 
				y que podrá ser modificada sin previo aviso, sujeta a las modificaciones que realice el transportista o 
				el distribuidor.</p>
				<p>*La información aquí presentada no será tomada en cuenta para la elaboración de los estados de cuenta.</p>
				<p>*Cuando existan discrepancias entre los datos de la aplicación y la información contenida en los estados de 
				cuenta, prevalecerá la información de los estados de cuenta.</p>
				<p>*La información relacionada con el tipo de cambio y el precio de gas son únicamente informativos, y no 
				puede considerarse como un precio firme.</p>
			</div>
			<br/>
			<div className="container_range_dates">
				<div>
					<p>De: {moment(fecha_inicio).format('YYYY-MM-DD')}</p>
				</div>
				<div>
					<p>Al:  {moment(fecha_fin).format('YYYY-MM-DD')}</p>
				</div>
			</div>
			<br/>
			{missing && (
				<>
				<div className="container_missing_dates">
					<div>
						<h3>Fechas faltantes:</h3>
					</div>
					{datesMissing.map((date_m)=>{
						return (
							<div key={date_m}>
								<div>
									<p >{date_m}</p>
								</div>
							</div>
						)
					}
					)}
				
				</div>
				<br/>
				</>
			)
			}
			{errorEmail && (
				<>
				<div className="container_mediciones_header">
					<h2>Hubo un error al envíar el correo</h2>
				</div>
				<br/>
				</>
			)
			}
			<div className="container_mediciones_resultado">
				<div className="botones_mediciones">
					<div className="volver_mediciones">
						<img src={volver2} alt="volver2" onClick={return_back}/>
						<p>Volver</p>
					</div>
					{ isData ? (
					<>
					<div className="descargar_mediciones">
						<img src={descargar} alt="descargar" onClick={descargar_csv}/>
						<p>Descargar</p>
					</div>
					<div className="enviar_mediciones" onClick={enviar_csv}>
						<img src={enviar} alt="enviar"/>
						<p>Enviar al correo</p>
					</div>
					</>
					):(null)
					}
				</div>
				{tipo === 'minutal' ? (
					isData ? (
						<div>
							<div className="header_tabla">
								<h2>La mediciones están KWH</h2>
								<p>Las mediciones con * son valores estimados</p>
							</div>
							<table id="mediciones_tabla">
								<tbody>
									<tr key={"minutal_header"}>
										<th>Timestamp</th>
										{
											isSuma ? (
											<th>Suma</th>

											):(
												select.map((slt_med)=>{
													return(
														<th key={slt_med}>{slt_med}</th>
													)
												})
											)
										}
									</tr>
									{
										create_table()
									}
								</tbody>
							</table>
						</div>
					) : (
						<div className="noinfo">
							<img src={noInfo} alt="noInfo"/>
						</div>
						)

					) : (

					isData ? (
						<div>
							<div className="header_tabla">
								<h2>La mediciones están KWH</h2>
								<p>Las mediciones con * son valores estimados</p>
							</div>
							<table id="mediciones_tabla">
								<tbody>
									<tr key={"horario_header"}>
										<th>Fecha</th>
										<th>Hora</th>
										{
											isSuma ? (
											<th>Suma</th>

											) :(
												select.map((slt_med)=>{
													return(
														<th key={slt_med}>{slt_med}</th>
													)
												})
											)
										}
									</tr>
									{
										
										create_table()
									}
								</tbody>
							</table>
						</div>
					): (
						<div className="noinfo">
							<img src={noInfo} alt="noInfo"/>
						</div>
					   )
				)}
			</div>
		</div>
		<br/>
		</>
	);

}