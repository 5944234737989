import React,{useState, useEffect} from 'react';
import "../../static/css/Cargas.css";
import {Link} from 'react-router-dom';
import Loader from '../Loader/Loader.js';
import Carga from '../Carga/Carga.js';
import Footer from "../Footer/Footer.js";
import volver from '../../static/img/volver.png';


export default function Cargas() {

	const Url_back = `${process.env.REACT_APP_URL_BACK}`;
	const [cargas, setCargas] = useState([]);
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(true);

	useEffect(() => {
    if (localStorage.getItem('token') !== '') {
        fetch(`${Url_back}/api/v1/carga/`,{
            method : 'GET',
            headers : {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        })
        .then(res => res.json())
        .then(data => {
        	if(data.status === "OK"){
        		setCargas(data.carga);
        	}else{
        		setErrors(true);
        	}
        	
        })
        .catch((error)=>{
        	setErrors(true);
        })
        .finally(() => {
        	setLoading(false);	
        }
        );
    }
    }, []);

	return(
		<>
		{loading && <Loader/>}
		<div className="volver_login">
			<Link to={`/menu`}>
				<img src={volver} alt="volver"/>
			</Link>
			<p>Volver</p>
		</div>

		{!errors ? (
		<>
		<div className="main_cargas">
			<div className="container_cargas">
				{
				cargas.map((carga) => {
					return(
					    <Carga key={carga} nombre={carga}/>
					)
				})
				}
			</div>
        </div>
        <Footer/>
		</>
		):(
		<>
		<div className="main_cargas">
			<div className="container_cargas">
				<h1>Hubo un error al buscar las cargas</h1>
			</div>
        </div>
        <Footer/>
		</>
		)}
		</>
		)
}