import React from "react";
import '../../static/css/Footer.css';

export default function Footer(props) {
    const {position} = props
    let style = {}
    if (position === "relative"){
        style = { position: 'relative'}
    }else{
        style = {position: 'fixed'}
    }

    return (
        <footer className="footer" style={style}>
            <h3>© Derechos Reservados </h3>
            <p>CFE Calificados | Ciudad de México | 2022</p>
        </footer>
    );
}