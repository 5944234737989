import React,{useEffect,useState} from 'react';
import Footer from "../Footer/Footer.js";
import password_img from "../../static/img/contraseña.png";
import Loader from '../Loader/Loader.js';
import new_usuario from "../../static/img/nuevousuario.png";
import logo_usuario from "../../static/img/usuario.png";
import correo_img from "../../static/img/correo.png";
import {Link} from 'react-router-dom';
import '../../static/css/Registro.css';
import volver from '../../static/img/volver.png';
import {AiOutlineSearch} from "react-icons/ai";

export default function Registro(){

    const Url_back = `${process.env.REACT_APP_URL_BACK}`;
	const array_nombres = [];
	const [cargas,setCargas] = useState([]);
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState(false);
	const [usuario,setUsuario] = useState("");
	const [correo,setCorreo] = useState("");
	const [password,setPassword] = useState("");
	const [nombreCliente,setNombreCliente] = useState("");
	const [cargaSlt,setCargaSlt] = useState([]);
	const [isSearching,setIsSearching] = useState(false);
	const [resultFilter,setResultFilter] = useState([]);
	const [created, setCreated] = useState(true);
	const [successfully, setSuccessfully] = useState(false);

	useEffect(() => {
		if (localStorage.getItem('token') !== ''){
			fetch(`${Url_back}/api/v1/cargas/`,{
				method : 'GET',
				headers : {
					'Content-Type': 'application/json',
                	'Authorization': `Token ${localStorage.getItem('token')}`,
				}
			})
			.then(res => res.json())
			.then(data => {
				if (data.status === "OK"){
					data.data.forEach(element => {array_nombres.push(element.nombre)});
					setCargas(array_nombres);
				}else{
					setErrors(true);
				}
			})
			.catch((error) => {
				setErrors(true);
			})
			.finally(() => {
				setLoading(false);
			});
		}
	},[]);

	const onSubmit = e => {
		e.preventDefault();
		setLoading(true)
		const new_user = {
	    "email": correo,
	    "carga": cargaSlt.toString(),
	    "nombre_cliente": nombreCliente,
	    "username": usuario,
	    "password": password,
		};
		if(localStorage.getItem('token') !== '' && cargaSlt !== ''){
			fetch(`${Url_back}/api/v1/users/`,{
				method : 'POST',
				headers : {
					'Content-Type': 'application/json',
                	'Authorization': `Token ${localStorage.getItem('token')}`,
				},
				body: JSON.stringify(new_user)
			})
			.then(res => {
				if (res.statusText !== "Created"){
					setCreated(false);
				}
			})
			.then(() =>
				{

					fetch(`${Url_back}/api/v1/asig_grupo/`,{
						method : 'POST',
						headers : {
							'Content-Type': 'application/json',
		                	'Authorization': `Token ${localStorage.getItem('token')}`,
						},
						body : JSON.stringify({"correo": correo})
					})
					.then(res => res.json())
					.then(data => {
						if (data.status === "OK"){
							setSuccessfully(true);
						}else{
							setErrors(true);
						}
					})
					.catch((error) => {
						setSuccessfully(false);
						setErrors(true);
					})
				}
			)
			.catch((error) => {
				setSuccessfully(false);
				setErrors(true);
			})
			.finally(() => {
				setLoading(false);
			})

		}
	}

	function return_register(){
		setLoading(true);
		window.location.reload(true);
		window.scrollTo(0,0);
	}

	function handlerClickCarga(e){
		const id = e.target.id;
		setCargaSlt([...cargaSlt,id]);
		setCargas(cargas.filter(item => item !== id));
		setResultFilter(resultFilter.filter(item => item !==id));
	}


	function handlerClickMedidorDelete(e){
		const id = e.target.id;
		setCargas([...cargas,id]);
		setCargaSlt(cargaSlt.filter(item => item !== id));
		setResultFilter([...resultFilter,id])
	} 


	function searching(e){
		var value;
		value = e.target.value.toUpperCase()
		if (value.length > 0){
			setIsSearching(true);
		}else{
			setIsSearching(false);
		}
		setResultFilter(cargas.filter(x => x.toUpperCase().includes(value)))
	}

	return(
		<>
			{loading && <Loader/>}
			{(!errors && !successfully) ? (
				<>
				<div className="volver_medidores">
					<Link to={`/menu`}>
						<img src={volver} alt="volver"/>
					</Link>
					<p>Volver</p>
				</div>
				<div className="container_form_register">
					<div className="form_register_header">
						<h2>Crear usuario</h2>
					</div>
					<form onSubmit={onSubmit}>
						<div className="container_form_new_user">
							<div className="form_username">
								<div>
									<img src={logo_usuario} alt="logo_usuario"/>
								</div>
								<div>
								  <input
								  name="usuario"
								  type='text'
								  placeholder="Usuario"
								  required
								  id="usuario"
								  value = {usuario}
								  onChange={e=> setUsuario(e.target.value)}
								  />
								</div>
							</div>
							<hr/>
							<div className="form_username">
								<div>
									<img src={new_usuario} alt="new_usuario"/>
								</div>
								<div>
								  <input
								  name="nombre_cliente"
								  type='text'
								  placeholder="Nombre del cliente"
								  required
								  id="nombre_cliente"
								  value = {nombreCliente}
								  onChange={e=> setNombreCliente(e.target.value)}
								  />
								</div>
							</div>
							<hr/>
							<div className="form_email">
								<div>
									<img src={correo_img} alt="correo"/>
								</div>
								<div>
								  <input
								  name="email"
								  type='email'
								  placeholder="Correo"
								  required
								  id="email"
								  value={correo}
								  onChange={e => setCorreo(e.target.value)}
								  />
								</div>
							</div>
							<hr/>
							<div className="form_password">
								<div>
									<img src={password_img} alt="password"/>
								</div>
								<div>
								  <input
								  name="password"
								  type='password'
								  placeholder="Contraseña"
								  required
								  id="password"
								  value={password}
								  onChange={e => setPassword(e.target.value)}
								  />
								</div>
							</div>
							<hr/>
						</div>
						<div className="form_register_header_selector">
							<h2>Seleccionar cargas</h2>
						</div>
						<br/>
						<div className={"search_bar_icon"}>
							<div>
								<AiOutlineSearch style={{ width: "120%", height: "100%"}}/>
							</div>
							<div>
								<input id="input_filter" onChange={searching} type="text" className="input_medidores"  placeholder="Buscar" />
							</div>
						</div>
						<br/>

						<div className="border_selectores_cargas">	
							<div className="selector_registro_cargas"  id="main_selector">
							{
								isSearching ? (
									
							        resultFilter.map((carga) => {
							          return (
										<div key={carga} onClick={handlerClickCarga}>
										    <div className="identificador_cargas" id={carga}> 
										        {carga}
										    </div>
									    </div>
							          );
							        })
								) : (

							        cargas.map((carga) => {
							          return (
										<div key={carga} onClick={handlerClickCarga}>
										    <div className="identificador_cargas" id={carga}> 
										        {carga}
										    </div>
									    </div>
							          );
							        })
								)
							}
							</div>
						</div>
						<br/>
						<div className="border_selectores_cargas">
							<div className="selecionado_registro_cargas">
								{cargaSlt.map((select) => {
										return(
											<div key={select} onClick={handlerClickMedidorDelete}>
											    <div className="identificador_cargas_seleccionado" id={select}> 
											        {select}
											    </div>
										    </div>										
										)		
									})}
							</div>
						</div>
						<br/>
						<div className="registrar_boton">
							<input type='submit' value="Registrar"></input>
						</div>

					</form>
				</div>		
				<br/>		
				<Footer position="relative"/>
				</>
			):(
				!created ? (
					<>
					<div className="volver_medidores">
						<Link to={`/menu`}>
							<img src={volver} alt="volver"/>
						</Link>
						<p>Volver</p>
					</div>
					<div className="container_form_register">
						<div className="register_header_status">
							<h2>Error al crear al usuario</h2>
						</div>
					</div>
					<Footer/>
					</>

				) : (


				(!errors && successfully) ? (
					<>
					<div className="volver_medidores">
						<img src={volver} alt="volver" onClick={return_register}/>
						<p>Volver</p>
					</div>
					<div className="container_form_register">
						<div className="register_header_status">
							<h2>Usuario creado con éxito</h2>
						</div>
					</div>
					<Footer/>
					</>

				) : 

				(
					<>
					<div className="volver_medidores">
						<Link to={`/menu`}>
							<img src={volver} alt="volver"/>
						</Link>
						<p>Volver</p>
					</div>
					<div className="container_form_register">
						<div className="register_header_status">
							<h2>Error al acceder al servicio</h2>
						</div>
					</div>
					<Footer/>
					</>
				)


				)
			)}
		</>
	)
}  