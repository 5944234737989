import React, {useState,useEffect} from "react";
import '../../static/css/Header.css';
import {NavLink,Link} from 'react-router-dom';
import logo from '../../static/img/logo.png';
import {MdClose} from "react-icons/md"
import {AiOutlineMenu} from "react-icons/ai"


export default function Header() {

    const cliente = localStorage.getItem('cliente');
    const [isAuth,setIsAuth] = useState(false);
    const [menuStatus, setMenuStatus] = useState(false);

    useEffect( () => {
        if (localStorage.getItem('token')) {
            setIsAuth(true);
        }
    },[]);

    const handleToggle = () => {
      setMenuStatus(!menuStatus)
    }

    const closeMenu = () => {
      setMenuStatus(false)
    }
    return (

            <div className="barra">
                { isAuth === true ? (
                    <>
                    <div className="links">
                        <div className="image">
                            <Link to={`/menu`}>
                               <img src={logo} alt="Logo"/> 
                            </Link>
                        </div>
                        <div className="nombre_cliente">
                            <h3>{cliente}</h3>
                        </div>
                        <div className="menuHeader">
                            <button onClick={handleToggle}>
                            {menuStatus ? (
                                <MdClose style={{ color: "#fff", width: "40px", height: "40px" }} />
                                ):(
                                    <AiOutlineMenu style={{ color: "#fff", width: "40px", height: "40px" }}/>
                                )}</button>
                            <ul className={`menuNav ${menuStatus ? " showMenu" : ""}`}>
                                <li><NavLink to='/menu' className="active-link" onClick={() => closeMenu()}>Menu</NavLink></li>
                                <li><NavLink to='/logout' className="active-link" onClick={() => closeMenu()}>Logout</NavLink></li>
                            </ul>
                        </div>
                    </div>                   
                    </>
                    ) : (
                    <div className="logo_img">
                        <img src={logo} alt="Logo"/>
                    </div>
                    )}
            </div>

    );
}