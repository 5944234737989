import React from 'react';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import './static/App.css';
import Header from "../Header/Header.js";
import Login from "../Login/Login.js";
import ResetPassword from "../Login/Reset_password.js";
import SuccessfullyReset from "../Login/Successfully_reset.js";
import FailedReset from "../Login/Failed_reset.js";
import Logout from "../Logout/Logout.js";
import Menu from "../Menu/Menu.js";
import Registro from "../Registro/Registro.js";
import Editar from "../Registro/Editar.js";
import Cargas from "../Cargas/Cargas.js";
import Medidores from '../Medidores/Medidores.js';
import NotLoggin from '../NotLoggin/NotLoggin.js';
import NotFound from '../NotFound/NotFound.js';
import UserEdit from '../Registro/user_edit.js';

export default function App() {
  
  return (

    <BrowserRouter>
      <Header/>
      <Routes>
      {
        localStorage.getItem('token') ? (
        <>
        <Route path="menu/" element={<Menu/>} exact/>
        <Route path="menu/registro" element={<Registro/>} exact/>
        <Route path="menu/editar" element={<Editar/>} exact/>
        <Route path="menu/editar/:id/" element={<UserEdit/>}/>
        <Route path="menu/medicion/" element={<Cargas/>}/>
        <Route path="menu/medicion/carga/:nombre/" element={<Medidores/>}/>
        <Route path="logout/" element={<Logout/>} exact/>
        <Route path="*" element={<NotFound/>}/>
        </>
        ):(
        <>
        <Route path="/" element={<Login/>} exact/>
        <Route path="/reset_pass/" element={<ResetPassword/>} exact/>
        <Route path="/successfully_reset_pass/" element={<SuccessfullyReset/>} exact/>
        <Route path="/failed_reset_pass/" element={<FailedReset/>} exact/>
        <Route path="*" element={<NotLoggin/>}/>
        </>
        )
      }
      </Routes>
    </BrowserRouter>
  );
}
