import React, {useState,useEffect} from 'react';
import '../../static/css/Logout.css';
import Loader from '../Loader/Loader.js';
import Footer from "../Footer/Footer.js";

export default function Logout(){

  const Url_back = `${process.env.REACT_APP_URL_BACK}`;
  const Url_front = `${process.env.REACT_APP_URL_FRONT}`;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('token') === '') {
      window.location.replace(`${Url_front}`);
    }
  }, []);

  const handleLogout = () => {
    setLoading(true)
    fetch(`${Url_back}/api/v1/token/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem('token')}`,
      }
    })
      .catch((error) => {
        console.error(error)
      })
      .finally(()=>{
        localStorage.clear();
        window.location.replace(`${Url_front}`);
        setLoading(false);
      });
  };

  return( 
      <>
      {loading && <Loader/>}
        <div className="content">
        	<div className="main">
        	 	<h2>¿Esta seguro que se quiere cerrar la sesión?</h2>
          		<br/>
          		<input className="logout" type='button' value='Logout' onClick={handleLogout} />
        	</div>
        </div>
      <Footer/>  
      </>
  );
}