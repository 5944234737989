import React from 'react';
import {Link} from "react-router-dom";
import Logo_medidor from "../../static/img/medidor.png";
import "../../static/css/Carga.css"

export default function Carga(props) {
	const {nombre} = props;


	return(
    <div key={nombre} className="inter_div_cargas"> 
        <Link to={`carga/${nombre}`}>
        	<div>
        		<img src={Logo_medidor} alt="Logo_medidor"/>
        	</div>
        	<div>
        		<h4>{nombre}</h4>
        	</div>
        </Link>
    </div>	    
	)	
}