import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import '../../static/css/Login.css';
import user_logo from '../../static/img/usuario.png';
import pass_logo from '../../static/img/pass.png';
import Loader from '../Loader/Loader.js';
import Footer from "../Footer/Footer.js";

export default function Login (){

    const Url_back = `${process.env.REACT_APP_URL_BACK}`;
    const Url_front = `${process.env.REACT_APP_URL_FRONT}`;
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [ErrorAuth,setErrorAuth] = useState(false);
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit = e => {
        e.preventDefault();
        setLoading(true)
        const user_login = {
            "password": password,
            "username": user
        };

        fetch(`${Url_back}/api/v1/token/login`,{
            method : 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user_login)
        })
        .then(res => res.json())
        .then(data => {
            if (data.auth_token){

                localStorage.clear();
                caches.keys().then((names) => {
                      names.forEach((name) => {
                        caches.delete(name);
                      });
                });
                localStorage.setItem('token', data.auth_token);

                fetch(`${Url_back}/api/v1/user/`,{
                    method : 'GET',
                    headers : {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${localStorage.getItem('token')}`,
                    }
                })
                .then(res => res.json())
                .then(data => {
                    caches.keys().then((names) => {
                          names.forEach((name) => {
                            caches.delete(name);
                          });
                    });
                    localStorage.setItem('usuario', data.nombre);
                    localStorage.setItem('email', data.email);
                    localStorage.setItem('cliente',data.cliente);
                    localStorage.setItem('grupo', data.grupo);
                    localStorage.setItem('minutal',data.minutal);
                    window.location.replace(`${Url_front}/menu`);
                });
            }else{
                setErrorAuth(true);
                setUser('');
                setPassword('');
                localStorage.clear();
            }
        })
        .catch((error) => {
            setErrors(true);
            setUser('');
            setPassword('');
            localStorage.clear();           
        })
        .finally(() => {
            setLoading(false);
        });
    };

  return (
    <>
    {loading && <Loader />}
    {ErrorAuth === true &&
        <div className="error">
            <h2>Usuario y/o contraseña incorrectas</h2>
        </div>
     }
    {errors === true &&
        <div className="error">
            <h2>Error al conectarse con el servidor</h2>
        </div>
     }
    <div className="content">
        <div className="title_page">
            <h2>Iniciar sesión</h2>
        </div>
        <div className="main">
        <form className="login_form" onSubmit={onSubmit}>
            <div className="inputs">
                <div>
                    <img src={user_logo} alt="user_logo"/>
                </div>
                <div>
                  <input
                    name='user'
                    type='text'
                    value={user}
                    placeholder="Usuario"
                    required
                    id="Usuario"
                    onChange={e => setUser(e.target.value)}
                  />{' '}
                </div>
              </div>
              <hr/>
              <br/>
              <div className="inputs">
                <div>
                   <img src={pass_logo} alt="pass_logo"/>
                </div>
                <div>
                  <input
                    name='password'
                    type='password'
                    value={password}
                    placeholder="Contraseña"
                    required
                    id="password"
                    onChange={e => setPassword(e.target.value)}
                  />{' '}
                </div>
              </div>
              <hr/>
              <br/>
              <div className="boton_login">
                  <input className="login" type='submit' value='Iniciar Sesión' />
              </div>                
        </form>
        </div>
        <div className="link_reset">
            <Link to={`/reset_pass`}>
                <p>¿Olvidaste tu contraseña?</p> 
            </Link>
        </div>
    </div>
    <Footer/>
    </>
  );
}